import * as Yup from 'yup';
import { dictionary1, dictionary2 } from '../../../screens/InsurancePolicy/InsurancePoliciesForms/dictionaries';

const createDetailValidation = (details, skipOthers) => {
  const getNullableValidation = () => Yup.string().nullable();

  const getRequiredValidation = () => Yup.string().required('Este campo es requerido');

  const getConditionalValidation = detail =>
    Yup.mixed().when(detail.showif.id, {
      is: value => detail.showif.selectedOption.includes(value),
      then: getRequiredValidation(),
      otherwise: getNullableValidation()
    });

  const getValidationForKey = (key, detail) => {
    if (skipOthers && key.includes('other')) {
      return getNullableValidation();
    }

    if (detail.required) {
      return detail.showif ? getConditionalValidation(detail) : getRequiredValidation();
    }

    return getNullableValidation();
  };

  return Object.keys(details).reduce((acc, key) => {
    const detail = details[key];
    acc[key] = getValidationForKey(key, detail);
    return acc;
  }, {});
};

const insuranceItemsAttributesSchema = (dictionaryType, skipOthers = false) => {
  let detailValidationSchema;

  switch (dictionaryType) {
    case 'dictionary_1':
      detailValidationSchema = dictionary1;
      break;
    case 'dictionary_2':
      detailValidationSchema = dictionary2;
      break;
    default:
      detailValidationSchema = {};
  }

  return Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required('Debes ingresar un nombre ítem')
        .max(160, 'Máx. 160 caracteres'),
      notes: Yup.string().max(1000, 'Máx. 1000 caracteres'),
      ...createDetailValidation(detailValidationSchema, skipOthers)
    })
  );
};

const commonValidation = {
  accountId: Yup.string().required('Debes seleccionar una cuenta'),
  beneficiaryPersonAddress: Yup.string().when('beneficiaryPersonName', {
    is: val => !!val,
    then: Yup.string().required('Debes ingresar dirección de beneficiario'),
    otherwise: Yup.string().nullable()
  }),
  beneficiaryPersonName: Yup.string().nullable(),
  beneficiaryPersonNationalIdentification: Yup.string().when('beneficiaryPersonName', {
    is: val => !!val,
    then: Yup.string()
      .required('Debes ingresar RUT de beneficiario')
      .rut('El RUT es inválido'),
    otherwise: Yup.string().nullable()
  }),
  // coverAmount: Yup.string().nullable(),
  // estimatedNetCommission: Yup.string().nullable(),
  estimatedNetPrime: Yup.string().nullable(),
  hiringPersonAddress: Yup.string().required('Debes ingresar dirección del contratante'),
  hiringPersonName: Yup.string().required('Debes ingresar nombre y apellido del contratante'),
  hiringPersonNationalIdentification: Yup.string()
    .required('Debes ingresar RUT del contratante')
    .rut('El RUT es inválido'),
  insuredPersonAddress: Yup.string().required('Debes ingresar dirección comercial del asegurado'),
  insuredPersonName: Yup.string().required('Debes ingresar nombre y apellido del asegurado'),
  insuredPersonNationalIdentification: Yup.string()
    .required('Debes ingresar RUT del asegurado')
    .rut('El RUT es inválido'),
  quotationRequestFile: Yup.mixed().nullable(),
  subjectMatter: Yup.string().nullable(),
  // subjectMatterAddress: Yup.string().nullable(),
  // validityStart: Yup.string().nullable(),
  responsibleEmail: Yup.string()
    .required('Debes ingresar tu correo electrónico')
    .email('Debes ingresar un correo electrónico válido'),
  insuranceItemsAttributes: Yup.mixed().when('dictionaryType', dictionaryType => {
    return insuranceItemsAttributesSchema(dictionaryType);
  })
};

const requestQuotationValidation = {
  ...commonValidation,
  coverDetail: Yup.string().nullable(),
  insuranceCategoryId: Yup.string().required('Debes seleccionar un ramo'),
  validityStart: Yup.date()
    .required('Debes ingresar un inicio de vigencia')
    .formatdate()
};

const firstStepFields = [
  'accountId',
  'beneficiaryPersonAddress',
  'beneficiaryPersonName',
  'beneficiaryPersonNationalIdentification',
  'hiringPersonName',
  'hiringPersonNationalIdentification',
  'hiringPersonAddress',
  'insuredPersonName',
  'insuredPersonNationalIdentification',
  'insuredPersonAddress',
  'responsibleEmail'
];

export { commonValidation, requestQuotationValidation, firstStepFields, insuranceItemsAttributesSchema };
