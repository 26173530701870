import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Field, Form, getIn, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';

import { openFile } from '../../services/utils';
import { IconBtn } from '../Utils/Button';
import { BasicTextAreaWithEditor, FormikInput, FormikMultiEmail } from '../Utils/Input';
import DropzoneFileSection from './DropzoneFileSection';

const SendEmailForm = ({
  errors,
  handleModalClose,
  isSubmitting,
  onHide,
  sendEmail,
  touched,
  setFieldValue,
  temporalDropzoneFiles,
  setTemporalDropzoneFiles,
  fileSection,
  fileSectionLabel,
  fileSectionDescription,
  showToField = true,
  showCcField = false,
  textCancelButton = 'Cancelar'
}) => (
  <Form>
    <Row>
      <Col xs={12}>
        <Field name="sendEmail[from]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              label="Correo electrónico remitente"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>

      {showToField && (
        <Col xs={12}>
          <Field name="sendEmail[to]">
            {({ field }) => (
              <FormikMultiEmail
                {...field}
                abbr
                label="Correo electrónico destinatario"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      )}

      {showCcField && (
        <Col xs={12}>
          <Field name="sendEmail[cc]">
            {({ field }) => (
              <FormikMultiEmail
                {...field}
                label="Correo electrónico destinatario CC"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      )}
      <Col xs={12}>
        <Field name="sendEmail[subject]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              label="Asunto"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>

      {fileSection ? (
        <AttachFileSection
          fileSectionLabel={fileSectionLabel}
          fileSectionDescription={fileSectionDescription}
          sendEmail={sendEmail}
          temporalDropzoneFiles={temporalDropzoneFiles}
          setTemporalDropzoneFiles={setTemporalDropzoneFiles}
        />
      ) : (
        sendEmail.attachments.length > 0 && <AttachedFiles attachments={sendEmail.attachments} />
      )}

      <Col xs={12}>
        <Field name="sendEmail[body]">
          {({ field }) => (
            <BasicTextAreaWithEditor
              {...field}
              value={field.value}
              label="Contenido"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              onChange={value => setFieldValue(field.name, value)}
            />
          )}
        </Field>
      </Col>
    </Row>

    <Row className="mt-2">
      <Col md={6} className="mt-4">
        <Button block variant="cancel" className="no-shadow" onClick={handleModalClose}>
          {textCancelButton}
        </Button>
      </Col>
      <Col md={6} className="mt-4">
        <Button block type="submit" variant="submit" className="no-shadow" onClick={onHide} disabled={isSubmitting}>
          Enviar
        </Button>
      </Col>
    </Row>
  </Form>
);

const AttachedFiles = ({ attachments }) => {
  const [attachmentArray, setAttachmentArray] = useState(attachments);
  const { setFieldValue } = useFormikContext();

  const handleRemoveFile = fileId => {
    const newArray = attachmentArray.filter(attachment => attachment.id !== fileId);
    setFieldValue('sendEmail[attachments]', newArray || []);
    setAttachmentArray(newArray);
  };

  return attachmentArray.map((attachment, index) => (
    <React.Fragment key={`attached-file-${index.toString()}`}>
      <Col
        xs={9}
        md={11}
        className="cursor-pointer"
        style={{ fontWeight: 500, display: 'inline-flex', alignItems: 'center' }}
        onClick={() => openFile(attachment)}
      >
        <IconBtn noBtn icon="attachment" iconSize="sm" variant="link" className="custom-icon-btn" />
        <p className="mb-0 text-truncate">{attachment.cleanFilename}</p>
      </Col>
      <Col xs={3} md={1} className="d-flex justify-content-center">
        <IconBtn
          icon="trash"
          variant="transparent"
          className="danger"
          onClick={() => handleRemoveFile(attachment.id)}
        />
      </Col>
    </React.Fragment>
  ));
};

const AttachFileSection = ({
  fileSectionLabel,
  fileSectionDescription,
  sendEmail,
  temporalDropzoneFiles,
  setTemporalDropzoneFiles
}) => {
  return (
    <>
      <Col xs={12}>
        <p className="section-title capitalize-text mt-3">{fileSectionLabel}</p>

        <p className="modal-body-icon__confirm-content">{fileSectionDescription}</p>
      </Col>

      {sendEmail.attachments.length > 0 && <AttachedFiles attachments={sendEmail.attachments} />}

      <Col xs={12}>
        <DropzoneFileSection
          multiple
          modelName="insurancePolicy"
          originalObject={sendEmail}
          temporalDropzoneFiles={temporalDropzoneFiles}
          setTemporalDropzoneFiles={setTemporalDropzoneFiles}
        />
      </Col>
    </>
  );
};

const setInitialValues = ({ sendEmail }) => ({ sendEmail });

const validationSchema = ({ showToField = true }) => {
  const toFieldValidation = showToField
    ? Yup.string().required('Debes ingresar un correo electrónico destinatario')
    : Yup.string().notRequired();

  return Yup.object().shape({
    sendEmail: Yup.object().shape({
      body: Yup.string().required('Debes ingresar un contenido'),
      from: Yup.string().required('Debes ingresar un correo electrónico remitente'),
      subject: Yup.string().required('Debes ingresar un asunto'),
      to: toFieldValidation
    })
  });
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(SendEmailForm);
