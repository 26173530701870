import * as Yup from 'yup';

import '../../../services/yupCustomMethods';
import { addDaysToDate } from '../../../services/utils';
import { dictionary1, dictionary2 } from './dictionaries';

// const CONTRACT_FILE_SIZE = 5 * 1024 * 1024;
// const CONTRACT_SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
// const SIGNATURE_FILE_SIZE = 5 * 1024 * 1024;
// const SIGNATURE_SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const createDetailValidation = (details, skipOthers) => {
  return Object.keys(details).reduce((acc, key) => {
    const detail = details[key];

    if (skipOthers && key.includes('other')) {
      acc[key] = Yup.string().nullable();
      return acc;
    }

    if (detail.required) {
      if (detail.showif) {
        acc[key] = Yup.mixed().when(detail.showif.id, {
          is: value => detail.showif.selectedOption.includes(value),
          then: Yup.string().required('Este campo es requerido'),
          otherwise: Yup.string().nullable()
        });
      } else {
        acc[key] = Yup.string().required('Este campo es requerido');
      }
    } else {
      acc[key] = Yup.string().nullable();
    }

    return acc;
  }, {});
};

const insuranceItemsAttributesSchema = (dictionaryType, skipOthers = false) => {
  let detailValidationSchema;

  switch (dictionaryType) {
    case 'dictionary_1':
      detailValidationSchema = dictionary1;
      break;
    case 'dictionary_2':
      detailValidationSchema = dictionary2;
      break;
    default:
      detailValidationSchema = {};
  }

  return Yup.array().of(
    Yup.object().shape({
      insuranceCoversAttributes: Yup.array().of(
        Yup.object().shape({
          amount: Yup.string().required('Debes ingresar un monto asegurado'),
          coverageType: Yup.string().required('Debes ingresar un tipo de cobertura'),
          fee: Yup.string().nullable(),
          name: Yup.string().required('Debes ingresar un detalle cobertura'),
          netPremium: Yup.string().required('Debes ingresar una prima neta')
        })
      ),
      name: Yup.string()
        .required('Debes ingresar un nombre ítem')
        .max(160, 'Máx. 160 caracteres'),
      notes: Yup.string().max(1000, 'Máx. 1000 caracteres'),
      ...createDetailValidation(detailValidationSchema, skipOthers)
    })
  );
};

const baseInsurancePolicyValidationSchema = {
  accountId: Yup.string().required('Debes seleccionar una cuenta'),
  acceptance: Yup.mixed().when('policyType', {
    is: value => value === 'proposal',
    then: Yup.string().required('Debes adjuntar una aprobación'),
    otherwise: Yup.string().nullable()
  }),
  affectCommissionPercentage: Yup.string().required('Debes ingresar un porcentaje para la comisión'),
  affectPremium: Yup.string().when('exemptPremium', {
    is: value => !value,
    then: Yup.string().required('Debes ingresar una prima afecta'),
    otherwise: Yup.string().nullable()
  }),
  beneficiaryPersonAddress: Yup.string().when('beneficiaryPersonName', {
    is: val => !!val,
    then: Yup.string().required('Debes ingresar una dirección de beneficiario'),
    otherwise: Yup.string().nullable()
  }),
  beneficiaryPersonName: Yup.string().nullable(),
  beneficiaryPersonNationalIdentification: Yup.string().when('beneficiaryPersonName', {
    is: val => !!val,
    then: Yup.string()
      .required('Debes ingresar RUT de beneficiario')
      .rut('El RUT es inválido'),
    otherwise: Yup.string().nullable()
  }),
  brokerCommissionAmount: Yup.string().required('Debes ingresar una comisión corredor'),
  brokerCommissionPercentage: Yup.string().required('Debes ingresar un porcentaje para la comisión'),
  cadenceType: Yup.string().required('Debes ingresar un tipo de cadencia'),
  comments: Yup.string().nullable(),
  commissionTotalAmount: Yup.number()
    .required('Debes ingresar una comisión total')
    .moreThan(0, 'La comisión total debe ser mayor a 0'),
  contract: Yup.mixed().when('policyType', {
    is: value => value === 'contract',
    then: Yup.string().required('Debes adjuntar una póliza'),
    otherwise: Yup.string().nullable()
  }),
  contractProposal: Yup.mixed().nullable(),
  exemptCommissionPercentage: Yup.string().required('Debes ingresar un porcentaje para la comisión'),
  exemptPremium: Yup.string().when('affectPremium', {
    is: value => !value,
    then: Yup.string().required('Debes ingresar una prima exenta'),
    otherwise: Yup.string().nullable()
  }),
  hiringPersonAddress: Yup.string().when('hiringPersonName', {
    is: val => !!val,
    then: Yup.string().required('Debes ingresar una dirección del contratante'),
    otherwise: Yup.string().nullable()
  }),
  hiringPersonName: Yup.string().nullable(),
  hiringPersonNationalIdentification: Yup.string().when('hiringPersonName', {
    is: val => !!val,
    then: Yup.string()
      .required('Debes ingresar un RUT del contratante')
      .rut('El RUT es inválido'),
    otherwise: Yup.string().nullable()
  }),
  insuranceCategoryId: Yup.string().required('Debes ingresar un ramo'),
  insuranceCompanyId: Yup.string().required('Debes ingresar una compañía aseguradora'),
  // insuranceItemsAttributes: Yup.array().of(
  //   Yup.object().shape({
  //     insuranceCoversAttributes: Yup.array().of(
  //       Yup.object().shape({
  //         amount: Yup.string().required('Debes ingresar un monto asegurado'),
  //         coverageType: Yup.string().required('Debes ingresar un tipo de cobertura'),
  //         fee: Yup.string().nullable(),
  //         name: Yup.string().required('Debes ingresar un detalle cobertura'),
  //         netPremium: Yup.string().required('Debes ingresar una prima neta')
  //       })
  //     ),
  //     name: Yup.string()
  //       .required('Debes ingresar un detalle ítem')
  //       .max(160, 'Máx. 160 caracteres'),
  //     notes: Yup.string().max(1000, 'Máx. 1000 caracteres')
  //   })
  // ),
  insuranceItemsAttributes: Yup.mixed().when('dictionaryType', dictionaryType => {
    return insuranceItemsAttributesSchema(dictionaryType);
  }),
  insuredPersonAddress: Yup.string().required('Debes ingresar una dirección comercial del asegurado'),
  insuredPersonName: Yup.string().required('Debes ingresar un nombre y apellido del asegurado'),
  insuredPersonNationalIdentification: Yup.string()
    .required('Debes ingresar RUT del asegurado')
    .rut('El RUT es inválido'),
  issueDate: Yup.date()
    .required('Debes ingresar una fecha de emisión')
    .formatdate(),
  nicoCommissionPercentage: Yup.string().required('Debes ingresar un porcentaje para la comisión'),
  paymentMethod: Yup.string().required('Debes ingresar una forma de pago'),
  paymentPlanId: Yup.string().required('Debes ingresar un plan de pago'),
  policyNumber: Yup.string().required('Debes ingresar un número de póliza'),
  quotation: Yup.mixed().when('policyType', {
    is: value => value === 'proposal',
    then: Yup.string().required('Debes adjuntar una cotización'),
    otherwise: Yup.string().nullable()
  }),
  renewalType: Yup.string().required('Debes ingresar un tipo de renovación'),
  subjectMatter: Yup.string().nullable(),
  subjectMatterAddress: Yup.string().nullable(),
  totalNetPremium: Yup.number()
    .required('Debes ingresar una prima total neta')
    .moreThan(0, 'La prima total neta debe ser mayor a 0'),
  validityEnd: Yup.date()
    .required('Debes ingresar un término de vigencia')
    .formatdate()
    .when('validityStart', (validityStart, schema) => {
      const limitDate = addDaysToDate(validityStart, 1);
      return validityStart && schema.min(limitDate, 'Debe ser mayor al inicio de vigencia');
    }),
  validityStart: Yup.date()
    .required('Debes ingresar un inicio de vigencia')
    .formatdate()
    .when('validityEnd', (validityEnd, schema) => {
      const limitDate = addDaysToDate(validityEnd, -1);
      return validityEnd && schema.max(limitDate, 'Debe ser menor al término de vigencia');
    })
};

const proposalExtraValidations = {
  contractFile: Yup.mixed().when('contractNumber', {
    is: value => value !== undefined,
    then: Yup.string().required('Debes adjuntar una póliza'),
    otherwise: Yup.string().nullable()
  }),
  contractNumber: Yup.mixed().when('contractFile', {
    is: value => value !== undefined,
    then: Yup.string().required('Debes ingresar un número de póliza'),
    otherwise: Yup.string().nullable()
  })
};

const renewalInsuranceItemsValidation = Yup.array().of(
  Yup.object().shape({
    name: Yup.string()
      .required('Debes ingresar un detalle ítem')
      .max(160, 'Máx. 160 caracteres'),
    notes: Yup.string().max(1000, 'Máx. 1000 caracteres')
  })
);

export const parsedInsurancePolicySchema = ({
  action,
  fromAdmin,
  proposalOptions,
  fromLeadProposal,
  leadPresent,
  ...props
}) => {
  const { isProposal = false, isRenewal } = proposalOptions || {};
  const { userModule } = props;

  let parsedSchema = fromAdmin
    ? {
        ...baseInsurancePolicyValidationSchema,
        executiveManagerId: Yup.string().required('Debes seleccionar un ejecutivo')
      }
    : baseInsurancePolicyValidationSchema;

  if (isProposal) parsedSchema = { ...parsedSchema, ...proposalExtraValidations };

  if (action === 'edit' || fromLeadProposal || leadPresent)
    parsedSchema = {
      ...parsedSchema,
      insuranceItemsAttributes: Yup.mixed().when('dictionaryType', dictionaryType => {
        return insuranceItemsAttributesSchema(dictionaryType, true);
      })
    };

  if (userModule === 'insured') {
    parsedSchema = {
      ...parsedSchema,
      affectCommissionPercentage: Yup.string().nullable(),
      brokerCommissionAmount: Yup.string().nullable(),
      brokerCommissionPercentage: Yup.string().nullable(),
      commissionTotalAmount: Yup.string().nullable(),
      exemptCommissionPercentage: Yup.string().nullable()
    };
  }

  if (isRenewal && isProposal)
    parsedSchema = { ...parsedSchema, insuranceItemsAttributes: renewalInsuranceItemsValidation };

  return Yup.object().shape({
    insurancePolicy: Yup.object().shape(parsedSchema, [
      ['affectPremium', 'exemptPremium'],
      ['validityEnd', 'validityStart'],
      ['contractFile', 'contractNumber']
    ])
  });
};

export const firstStepFields = [
  'accountId',
  'beneficiaryPersonAddress',
  'beneficiaryPersonName',
  'beneficiaryPersonNationalIdentification',
  'hiringPersonAddress',
  'hiringPersonName',
  'hiringPersonNationalIdentification',
  'insuredPersonAddress',
  'insuredPersonName',
  'insuredPersonNationalIdentification'
];

export const secondStepFields = [
  'affectCommissionPercentage',
  'affectPremium',
  'brokerCommissionAmount',
  'brokerCommissionPercentage',
  'cadenceType',
  'commissionTotalAmount',
  'exemptCommissionPercentage',
  'exemptPremium',
  'insuranceCategoryId',
  'insuranceItemsAttributes',
  'issueDate',
  'nicoCommissionPercentage',
  'paymentMethod',
  'paymentPlanId',
  'renewalType',
  // 'subjectMatter',
  // 'subjectMatterAddress',
  'totalNetPremium',
  'validityEnd',
  'validityStart'
];

export const thirdStepFields = [
  'acceptance',
  'comments',
  'contract',
  'contractFile',
  'contractNumber',
  'contractProposal',
  'insuranceCompanyId',
  'policyNumber',
  'quotation'
];
