import React from 'react';
import { useHistory } from 'react-router-dom';
import { BadgeIcon } from '../Badge';

const DataTableBadges = ({ row, fromAdmin }) => {
  const { badges } = row;
  const history = useHistory();
  const policyModelUrl = fromAdmin ? `/admin/insurance_policies` : '/insurance_policies';

  const handlePolicyClick = () => {
    const { insurancePolicy } = row;
    return history.push(`${policyModelUrl}/${insurancePolicy.id}`);
  };

  const debtTooltipText = title => {
    if (!badges.debtInfo) return title;

    const { debtStartDate, debtAmount, debtInstallment } = badges.debtInfo;

    let debtCardText = `<br/>Fecha de inicio: ${debtStartDate}`;
    if (debtAmount) debtCardText += `<br/>Monto deuda: ${debtAmount}`;
    if (debtInstallment) debtCardText += `<br/>Número cuota: ${debtInstallment}`;
    return `${title} ${debtCardText}`;
  };

  return (
    <div className="datatable-badges--container d-flex flex-wrap">
      {badges.withDebt && <BadgeIcon icon="dollar" variant="danger" tooltipText={debtTooltipText('Con Deuda')} />}
      {badges.withCriticalDebt && (
        <BadgeIcon icon="ghost-character" variant="dark" tooltipText={debtTooltipText('Deuda crítica')} />
      )}
      {badges.withSinister && <BadgeIcon icon="smile-sad" variant="danger" tooltipText="Con siniestro" />}
      {badges.withPolicy && (
        <BadgeIcon icon="readme" variant="success" tooltipText="Póliza creada" onClick={handlePolicyClick} />
      )}
      {badges.isRenewable && <BadgeIcon icon="sync" variant="success" tooltipText="Renovable" />}
      {badges.isExtendable && <BadgeIcon icon="sand-clock" variant="success" tooltipText="Prorrogable" />}
      {badges.withQuotation && <BadgeIcon icon="notes" variant="warning" tooltipText="Cotizaciones disponibles" />}
      {badges.nearInvalid && <BadgeIcon icon="danger" variant="warning" tooltipText="Póliza por vencer" />}
      {badges.isUnwanted && <BadgeIcon icon="debug" variant="dark" tooltipText="No deseado" />}
      {badges.isVip && <BadgeIcon icon="asterisk" variant="primary-dark" tooltipText="Cliente VIP" />}
      {badges.hasInsuredUser && (
        <BadgeIcon icon="user" variant="info" tooltipText="Asegurado está marcado como correo encargado" />
      )}
    </div>
  );
};

export default DataTableBadges;
