import React from 'react';
import { getIn, useFormikContext } from 'formik';
import {
  LicensePlateField,
  NumberField,
  RadioField,
  SelectField,
  TextAreaField,
  TextField
} from './dictionaryFields/index';

const DictionaryFields = ({ modelName, dictionaryDetails = [], action, fromLeadProposal, leadPresent, ...props }) => {
  const { values, errors, touched } = useFormikContext();
  const { isProposal, isRenewal } = props;

  const shouldShowField = showif => {
    if (!showif) return true;
    const selectedOption = getIn(values, `${modelName}[${showif?.id}]`);
    return showif?.selectedOption.includes(selectedOption);
  };

  const showChildField = showif => {
    return (
      shouldShowField(showif) &&
      ((action === 'new' && !fromLeadProposal && !leadPresent) || (action === 'new' && isRenewal && isProposal))
    );
  };

  const optionalField = isRenewal && (action === 'requestQuotation' || isProposal);

  const renderField = (key, details) => {
    const { format, id, showif } = details;

    if (id.includes('other')) {
      if (showChildField(showif)) {
        return (
          <ChildField
            key={key}
            keyValue={id}
            details={details}
            modelName={modelName}
            errors={errors}
            touched={touched}
            optional={optionalField}
          />
        );
      }
      return null;
    }

    switch (format) {
      case 'text':
        return (
          <TextField
            key={key}
            keyValue={id}
            details={details}
            modelName={modelName}
            errors={errors}
            touched={touched}
            optional={optionalField}
          />
        );
      case 'licenseplate':
        return (
          <LicensePlateField
            key={key}
            keyValue={id}
            details={details}
            modelName={modelName}
            errors={errors}
            touched={touched}
            optional={optionalField}
          />
        );
      case 'select':
        return (
          <SelectField
            key={key}
            keyValue={id}
            details={details}
            modelName={modelName}
            errors={errors}
            touched={touched}
            optional={optionalField}
          />
        );
      case 'radio':
        return (
          <RadioField
            key={key}
            keyValue={id}
            details={details}
            modelName={modelName}
            errors={errors}
            touched={touched}
            optional={optionalField}
          />
        );
      case 'alphanumeric':
        return (
          <NumberField
            key={key}
            keyValue={id}
            details={details}
            modelName={modelName}
            errors={errors}
            touched={touched}
            optional={optionalField}
          />
        );
      default:
        return null;
    }
  };

  return <>{dictionaryDetails.map((detail, index) => renderField(index, detail))}</>;
};

const ChildField = ({ keyValue, details, modelName, errors, touched, optional }) => {
  const { format } = details;

  switch (format) {
    case 'text':
      return (
        <TextField
          keyValue={keyValue}
          details={details}
          modelName={modelName}
          errors={errors}
          touched={touched}
          optional={optional}
        />
      );
    case 'select':
      return (
        <SelectField
          keyValue={keyValue}
          details={details}
          modelName={modelName}
          errors={errors}
          touched={touched}
          optional={optional}
        />
      );
    case 'alphanumeric':
      return (
        <NumberField
          keyValue={keyValue}
          details={details}
          modelName={modelName}
          errors={errors}
          touched={touched}
          optional={optional}
        />
      );
    case 'textarea':
      return (
        <TextAreaField
          keyValue={keyValue}
          details={details}
          modelName={modelName}
          errors={errors}
          touched={touched}
          optional={optional}
        />
      );
    case 'radio':
      return (
        <RadioField
          keyValue={keyValue}
          details={details}
          modelName={modelName}
          errors={errors}
          touched={touched}
          optional={optional}
        />
      );
    default:
      return null;
  }
};
export default DictionaryFields;
